import React, { useState, useRef, useEffect, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import comentIcon from "../../assets/comentIcon.svg";
import logoIvi from "../../assets/logo.svg";
import question from "../../assets/question.svg";
import ButtonComponent from "../../components/ButtonComponent";
import CountdownTimer from "../../components/Countdown";
import LeftHomeComponent from "../../components/leftHomeComponent/LeftHomeComponent";
import MainHomeComponent from "../../components/MainHomeComponent/MainHomeComponent";
import RightHomeComponent from "../../components/RightHomeComponent/RightHomeComponent";
import "./validation.css";
import { useMutation } from "@tanstack/react-query";
import { sendToken } from "../../services/authentication";
import { JsonCreateLeadProps } from "../../services/onboarding";
import { POST_SEND_TOKEN } from "../../query/constants";
import { useInitAuth } from "../../hooks/useInitAuth";
import { RedirectContext } from "../../context/redirect";
import { useGetLead } from "../../hooks/useGetLead";
import { SessionContext } from "../../context/session";
import LoadingFullPage from "../../components/Loading/LoadingFullPage/LoadingFullPage";
import UseNewLead from "../../hooks/useNewLead";
import { useGetProtectedLead } from "../../hooks/useGetProtectedLead";
import { useGetDistribuidora } from "../../hooks/useGetDistribuidora";
import { getImage } from "../../services/images";
import { getParceiroId } from "../../services/parceiro";
import { TokenInput } from "./tokenInput";
import { textsPath } from "../../services/texts";

export const Validation = () => {
  const { getItemSession } = useContext(SessionContext);
  const navigate = useNavigate();
  const { continueResgistration } = useContext(RedirectContext);

  const [errorMgs, setErrorMgs] = useState<string>("");
  const [invalidNumber, setInvalidNumber] = useState(false);
  const [emailStorage, setEmailStorage] = useState<string>("");
  const [timeOut, setTimeOut] = useState<boolean>(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState<boolean>(false);
  const [code, setCode] = useState("");
  const [contactStorage, setContactStorage] = useState<JsonCreateLeadProps>({
    nome: "",
    telefone: "",
    distribuidora: "",
    acceptTime: "",
  });
  const [texts, setTexts] = useState({ description: "", title: "" });
  const { title, description } = texts;

  const initAuth = useInitAuth();
  const getLead = useGetLead();
  const createLead = UseNewLead();
  const getDistribuidora = useGetDistribuidora();
  const getProtectedLead = useGetProtectedLead();

  const mutation = useMutation({
    mutationFn: sendToken,
    mutationKey: [POST_SEND_TOKEN],
    onSuccess(data) {
      if (data.status !== "SUCESSO") {
        throw new Error();
      }
      sessionStorage.setItem("token", data.token);

      if (!continueResgistration) {
        creatNewLead();
      } else {
        // getLead.mutate(emailStorage);
        getProtectedLead.mutate();
      }
    },
    onError(error) {
      setInvalidNumber(true);
      setErrorMgs(
        " Por favor, digite o código de 6 dígitos enviado para seu e-mail"
      );
    },
  });

  function creatNewLead() {
    createLead.mutate({
      nome: contactStorage.nome,
      telefone: contactStorage.telefone,
      distribuidora: contactStorage.distribuidora,
      parceiroId: getParceiroId(),
      acceptTime: contactStorage.acceptTime,
    });
  }

  useEffect(() => {
    const storageContact = getItemSession("contact");
    const distribuidora = getItemSession("distribuidora") as string;
    const emailContinue = getItemSession("emailContinue");

    if (storageContact && distribuidora) {
      const contactModifyStorage = JSON.parse(storageContact);
      const newDistribuidora = distribuidora;

      setContactStorage({
        nome: contactModifyStorage.name,
        telefone: contactModifyStorage.phone,
        distribuidora: newDistribuidora,
        acceptTime: contactModifyStorage.acceptTime,
      });
      setEmailStorage(contactModifyStorage.email);
    } else if (emailContinue) {
      setEmailStorage(emailContinue);
    }
    if (emailContinue) {
      return setEmailStorage(emailContinue);
    }
    setupTexts();
  }, []);

  const setupTexts = () => import(`./${textsPath}`).then(setTexts);

  const handleExpire = () => {
    setTimeOut(true);
  };

  const handleClickReenvio = () => {
    setTimeOut(false);

    initAuth.mutate({ email: emailStorage });
  };

  const handleBackButtonClick = () => {
    navigate("/contact");
  };

  const handleButtonClick = () => {
    mutation.mutate({ email: emailStorage, token: code });
  };

  const renderCountdown = useMemo(() => {
    return <CountdownTimer seconds={40} handleComplete={handleExpire} />;
  }, []);

  return (
    <MainHomeComponent>
      <LeftHomeComponent
        logoSrc={logoIvi}
        title={title}
        description={description}
        imgSrc={getImage("validation")}
      />
      <RightHomeComponent
        questionSrc={question}
        commentIconSrc={comentIcon}
        cardHomeClass="container-info-validation"
        title={title}
        description={description}
      >
        <div className="input-container">
          <TokenInput
            setIsButtonEnabled={setIsButtonEnabled}
            setErrorMgs={setErrorMgs}
            setCode={setCode}
          />
          {invalidNumber && <p className="error-mgs-validation">{errorMgs}</p>}
          <div className="score-container">
            <p
              className={timeOut ? "text-send-error" : "text-Send"}
              onClick={handleClickReenvio}
            >
              Reenviar código:{" "}
            </p>

            {timeOut ? <span>00:00</span> : renderCountdown}
          </div>
          <div className="button-container-validation">
            <ButtonComponent
              className="back-button"
              // className="btn-voltar"
              onClick={handleBackButtonClick}
            >
              Voltar
            </ButtonComponent>

            <ButtonComponent
              className="continue-button"
              // className="btn-contact"
              onClick={handleButtonClick}
              isDisabled={!isButtonEnabled ? true : false}
            >
              Continuar
            </ButtonComponent>
          </div>
        </div>
        {(getProtectedLead.isPending ||
          getDistribuidora.isPending ||
          getLead.isPending ||
          createLead.isPending ||
          initAuth.isPending ||
          mutation.isPending) && <LoadingFullPage />}
      </RightHomeComponent>
    </MainHomeComponent>
  );
};
