import { Input } from "@nextui-org/react";
import comentIcon from "../../assets/comentIcon.svg";
import logoIvi from "../../assets/logo.svg";
import question from "../../assets/question.svg";
import ButtonComponent from "../../components/ButtonComponent";
import LeftHomeComponent from "../../components/leftHomeComponent/LeftHomeComponent";
import MainHomeComponent from "../../components/MainHomeComponent/MainHomeComponent";
import RightHomeComponent from "../../components/RightHomeComponent/RightHomeComponent";
import "./dadosParceiro.css";
import { description, title } from "./texts";
import { useContext, useState } from "react";
import { SessionContext } from "../../context/session";
import { isValidEmail } from "../../services/validations";
import { useNavigate } from "react-router-dom";
import { DadosParceiroType } from "../../services/onboarding";

export const DadosParceiro = () => {
  const { getItemSession, setItemSession } = useContext(SessionContext);

  const initialData = !!getItemSession("DadosParceiro")
    ? JSON.parse(getItemSession("DadosParceiro")!)
    : null;
  const navigate = useNavigate();

  const [idSalesforce, setIdSalesforce] = useState<string | undefined>(
    initialData?.idSalesforce
  );
  const [dealerEmail, setDealerEmail] = useState<string | undefined>(
    initialData?.dealerEmail
  );

  const [idSalesforceChanged, setIdSalesforceChanged] = useState(false);
  const [dealerEmailChanged, setDealerEmailChanged] = useState(false);

  const isIdSalesforceValid = !!idSalesforce;
  const isDealerEmailValid = dealerEmail && isValidEmail(dealerEmail);
  // const isFormValid = isIdSalesforceValid && isDealerEmailValid;
  const isFormValid = isDealerEmailValid;

  const handleButtonClick = () => {
    if (!isFormValid) return;
    const dadosParceiro: DadosParceiroType = {
      idSalesforceParceiro: idSalesforce,
      emailDealer: dealerEmail,
    };
    setItemSession("DadosParceiro", JSON.stringify(dadosParceiro));
    navigate("/distribuidora");
  };

  return (
    <MainHomeComponent>
      <LeftHomeComponent
        logoSrc={logoIvi}
        title={title}
        description={description}
        showCalculator={true}
      />
      <RightHomeComponent
        title={title}
        description={description}
        questionSrc={question}
        commentIconSrc={comentIcon}
        cardHomeClass="container-contact"
      >
        <div className="container-contact-form">
          {/* nome */}
          <div className="container-inputs-contact">
            <Input
              type="text"
              size="md"
              label="ID da oportunidade no SFA"
              value={idSalesforce}
              onChange={(ev) => {
                setIdSalesforceChanged(true);
                setIdSalesforce(ev.target.value);
              }}
              color="primary"
              // isInvalid={!isIdSalesforceValid && idSalesforceChanged}
              classNames={{
                input: ["text-kumbh-sans"],
                label: ["font-kumbh-sans"],
                inputWrapper: [
                  "w-[280px] h-14 bg-transparent data-[hover=true]:bg-transparent group-data-[focus=true]:bg-transparent",
                ],
              }}
            />
          </div>
          {!isIdSalesforceValid && idSalesforceChanged && (
            <p className="error-mgs-contact">IdInvalido</p>
          )}
          <div className="container-inputs-contact">
            <Input
              type="text"
              size="md"
              label="Seu e-mail corporativo"
              value={dealerEmail}
              onChange={(ev) => {
                setDealerEmailChanged(true);
                setDealerEmail(ev.target.value);
              }}
              color="primary"
              isInvalid={!isDealerEmailValid && dealerEmailChanged}
              classNames={{
                input: ["text-kumbh-sans"],
                label: ["font-kumbh-sans"],
                inputWrapper: [
                  "w-[280px] h-14 bg-transparent data-[hover=true]:bg-transparent group-data-[focus=true]:bg-transparent",
                ],
              }}
            />
          </div>
          {!isDealerEmailValid && dealerEmailChanged && (
            <p className="error-mgs-contact">E-mail inválido</p>
          )}

          <div className="container-button-contact">
            <ButtonComponent
              className="continue-button"
              // className="btn-continue-contact"
              style={{ width: "300px" }}
              onClick={handleButtonClick}
              isDisabled={!isFormValid}
            >
              Gerar uma proposta agora
            </ButtonComponent>
          </div>
        </div>
      </RightHomeComponent>
    </MainHomeComponent>
  );
};
