import React, { Ref, useImperativeHandle, useState } from "react";
import Modal from "../../components/modal/Modal";
import AlertEmail from "../../assets/contact/alert-email.png";
import ButtonComponent from "../../components/ButtonComponent";
import { ModalRef } from "../../utils/modal";

export const ModalAguardandoAssinaturaCliente = React.forwardRef(
  (_, ref: Ref<ModalRef>) => {
    const [isOpen, setIsOpen] = useState(false);
    const open = () => {
      setIsOpen(true);
    };
    const close = () => {
      setIsOpen(false);
    };

    useImperativeHandle(ref, () => ({
      open,
      close,
    }));

    return isOpen ? (
      <div className="modal-position">
        <Modal id="modal-erros" onClose={() => close()}>
          <div className="modal-error-header">
            <img width="40px" height="40px" src={AlertEmail} alt="" />
            <h1 className="modal-error-title">
              Aguardando assinatura do cliente
            </h1>
            <p className="email-exist-text">
              O e-mail informado já possui uma proposta aguardando assinatura.
              Favor aguardar assinatura do cliente para realizar o próximo
              cadastro.
            </p>
          </div>
          <div className="container-button-email-exists">
            <ButtonComponent className="btn-voltar-exist" onClick={close}>
              OK
            </ButtonComponent>
          </div>
        </Modal>
      </div>
    ) : (
      <></>
    );
}) 