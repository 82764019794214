import { useEffect, useState } from "react";
import ButtonComponent from "../../components/ButtonComponent";
import logoIvi from "../../assets/fotos-vivo/logo-Ivi.svg";

import { useNavigate } from "react-router-dom";
import SecondaryScreen from "../../components/SecondaryScreen";
import question from "../../assets/question.svg";
import comentIcon from "../../assets/comentIcon.svg";
import CheckIcon from "../../assets/pdfErrorValidation/check.png";
import "./pdfErrorValidation.css";
import { textsPath } from "../../services/texts";

function PdfErrorValidation() {
  const navigate = useNavigate();

  const [texts, setTexts] = useState({ description: "", title: "" })
  const { title, description } = texts;

  useEffect(() => {
    setupTexts();
  }, []);

  const setupTexts = () => import(`./${textsPath}`).then(setTexts);

  const handleButtonClick = () => {
    navigate("/");
  };
  return (
    <SecondaryScreen
      logoSrc={logoIvi}
      iconOne={question}
      iconTwo={comentIcon}
    >
      <div className="middle-content-pdf-error">
        <img src={CheckIcon} alt="" />
        <h1 className="title-pdf-error-validation">
          {title}
        </h1>
        <p className="text-pdf-error">
          {description}
        </p>
        <ButtonComponent
          className="continue-button"
          style={{ width: "192px", margin: "20px auto", letterSpacing: "1px" }}
          //  className="btn-homee"
          onClick={handleButtonClick}
        >
          Voltar para tela inicial
        </ButtonComponent>
      </div>
    </SecondaryScreen>
  );
}

export default PdfErrorValidation;
