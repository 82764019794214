import { api } from "../lib/axios/config";

export type updateContato = {
  Nome: string;
  Telefone: string;
};

export type JsonCreateLeadProps = {
  nome: string;
  telefone: string;
  distribuidora: string;
  parceiroId?: string;
  idSalesforceParceiro?: string;
  emailDealer?: string;
  acceptTime?: string;
};
export type DadosParceiroType = {
  idSalesforceParceiro?: string;
  emailDealer: string;
};

export type DadosTitularPjProps = {
  CNPJ: string;
  RazaoSocial: string;
};

type DadosTitularProps = {
  Nome: string;
  CPF: string;
  RG: string;
  Nacionalidade: string;
  EstadoCivil: string;
};

export type DadosRepresentanteProps = {
  CNPJ: string;
  RazaoSocial: string;
  Representante: DadosTitularProps;
};

type DistNotFoundProps = {
  Email: string;
  Distribuidora: string;
};

type ConfirmDadosLeadProps = {
  Contato: updateContato;
  dadosRepresentante: DadosTitularProps;
  isCnpj: boolean;
};

export async function criarLead(jsonCreatLead: JsonCreateLeadProps) {
  const response = await api.post("onboarding/new-lead", jsonCreatLead);
  return response.data;
}

export async function distributoraUpdate(distribuidora: string) {
  const response = await api.post("update-distribuidora", { distribuidora });
  return response.data;
}

export async function parseFatura(form: FormData) {
  const response = await api.post("onboarding/parse-fatura", form, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=---011000010111000001101001",
    },
  });
  return response.data;
}

export async function confirmarDadosFatura(form: FormData) {
  const response = await api.post("onboarding/confirmar-dados-fatura", form, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=---011000010111000001101001",
    },
  });
  return response.data;
}

//excluir depois o abaixo:
export async function createDadosTitularPf(dadosTitulaPf: DadosTitularProps) {
  const response = await api.post(
    // substituir a rota por: (create-titular-pf)
    "onboarding/create-titular-pf",
    dadosTitulaPf
  );
  return response.data;
}

export async function createDadosTitularPJ({
  CNPJ,
  RazaoSocial,
  Representante,
}: DadosRepresentanteProps) {
  const response = await api.post("onboarding/create-titular-pj", {
    CNPJ,
    RazaoSocial,
    Representante,
  });
  return response.data;
}

export type Distribuidora = {
  id: string;
  nome: string;
  tarifaVigente: number;
  descontoPadrao: number;
};

export async function getDistribuidoras(): Promise<Distribuidora[]> {
  const response = await api.get(
    `onboarding/get-distribuidoras?${
      process.env.REACT_APP_PARCEIRO_ID
        ? `parceiroId=${process.env.REACT_APP_PARCEIRO_ID}`
        : ""
    }`
  );
  return response.data;
}

export async function termo() {
  const response = await api.post("onboarding/prepare-term");
  return response.data;
}

export async function updateDistribuidora(distribuidora: string) {
  const response = await api.post("onboarding/update-distribuidora", {
    distribuidora: distribuidora,
  });

  return response.data;
}

export type GetLeadResponse = {
  status: string;
  statusOnboarding: string;
  isFromParceiro: boolean;
  aguardandoAssinaturaCliente: boolean;
};

export async function getLead(
  email: string,
  token?: string
): Promise<GetLeadResponse> {
  const response = await api.get(`onboarding/get-lead?email=${email}`, {
    headers: { "x-recaptcha-token": token },
  });
  return response.data;
}

export type ProtectedLeadResponse = {
  status: string;
  onboardingStatus: string;
  contato: {
    email: string;
    nome: string;
    telefone: string;
  };
  dadosParceiro?: DadosParceiroType;
  instalacao: {
    distribuidoraId: string;
    distribuidora: {
      id: string;
      nome: string;
      tarifaVigente: number;
      descontoPadrao: number;
    };
    classificacao?: string;
    fornecimento?: string;
    numeroInstalacao?: string;
    endereco?: string;
    titular: {
      tipo: "pf" | "pj";
      cpf: string | null;
      cnpj: string | null;
      nome: string | null;
      razaoSocial?: string;
      rg: string | null;
      estadoCivil: string | null;
      nacionalidade?: string;
      representante: {
        cpf: string | null;
        nacionalidade?: string;
        rg: string | null;
        nome?: string;
        estadoCivil?: string;
      };
    };
    fatura: {
      consumoMedio?: number;
      instalacaoId: string | null;
    };
  };
};

export async function getProtectedLead(): Promise<ProtectedLeadResponse> {
  const response = await api.get("onboarding/get-protected-lead");

  return response.data;
}

export async function validateCnpj(cnpj: string) {
  const response = await api.post(`onboarding/validate-cnpj?cnpj=${cnpj}`);
  return response.data;
}

export async function validateCpf(cpf: string) {
  const response = await api.post(`onboarding/validate-cpf?cpf=${cpf}`);
  return response.data;
}

export async function updateLeadStatus() {
  const response = await api.put("onboarding/update-lead-status");
  return response.data;
}

export async function confirmarDadosLead({
  Contato,
  isCnpj,
  dadosRepresentante,
}: ConfirmDadosLeadProps) {
  let nomeObjetoDados = "";
  if (isCnpj) {
    nomeObjetoDados = "Representante";
  } else {
    nomeObjetoDados = "TitularPF";
  }
  const response = await api.put("onboarding/confirmar-dados-lead", {
    Contato,
    [nomeObjetoDados]: { ...dadosRepresentante },
  });
  return response.data;
}

export async function changeStatusLead(email: string) {
  const response = await api.put(`onboarding/reprove-lead?email=${email}`);
  return response.data;
}

export async function distNotFound({
  Email,
  Distribuidora,
}: DistNotFoundProps) {
  const response = await api.post("onboarding/create-lead-dist-not-found", {
    Email,
    Distribuidora,
    ParceiroId: process.env.REACT_APP_PARCEIRO_ID ?? undefined,
  });
  return response.data;
}
